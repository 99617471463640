<template>
    <div class="mandp-beers-section">

        <div class="mandp-beers-section--header"></div>

        <div class="grid">
            <div v-if="Sol" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Sol.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Sol.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="XXLager" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Dos-equis-Lager.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ XXLager.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="XXAmbar" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Dos-equis-Amber.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ XXAmbar.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="Miller" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Miller-Lite.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Miller.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="CoorsLight" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Coors-light.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ CoorsLight.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="Indio" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Indio.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Indio.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="TecateO" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Tecate-Original.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ TecateO.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="TecateLight" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Tecate-Light.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ TecateLight.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="CartaBlanca" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Carta-blanca.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ CartaBlanca.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="Superior" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Superior.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Superior.price }}
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array, }
        },
        computed: {
            Sol(){
                return this.products.filter(p => p.name === 'Sol')[0]
            },
            XXLager(){
                return this.products.filter(p => p.name === 'XX Lager')[0]
            },
            XXAmbar(){
                return this.products.filter(p => p.name === 'XX Ambar')[0]
            },
            Miller(){
                return this.products.filter(p => p.name === 'Miller Lite')[0]
            },
            CoorsLight(){
                return this.products.filter(p => p.name === 'Coors Light')[0]
            },
            Indio(){
                return this.products.filter(p => p.name === 'Indio')[0]
            },
            TecateO(){
                return this.products.filter(p => p.name === 'Tecate')[0]
            },
            TecateLight(){
                return this.products.filter(p => p.name === 'Tecate Light')[0]
            },
            CartaBlanca(){
                return this.products.filter(p => p.name === 'Carta Blanca')[0]
            },
            Superior(){
                return this.products.filter(p => p.name === 'Superior')[0]
            },
        }
    }
</script>
