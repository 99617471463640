<template>
    <div :class="`mandp-menu-beers-non-alcoholic`">

        <div class="mandp-menu-beers-non-alcoholic--price hnk">
            <p v-if=" Heineken ">
                {{ Heineken?.price }}
            </p>
            <p v-else class="empty">No disponible</p>
        </div>

        <div class="mandp-menu-beers-non-alcoholic--price tkt">
            <p v-if=" Tecate ">
                {{ Tecate?.price }}
            </p>
            <p v-else class="empty">No disponible</p>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array }
        },
        computed: {
            Heineken(){
                return this.products.filter(p => p.name === 'Heineken 0.0')[0]
            },
            Tecate(){
                return this.products.filter(p => p.name === 'Tecate 0.0')[0]
            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>
