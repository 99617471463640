<template>
    <div class="mandp-sider-section">
        <div class="grid">
            <div class="col-5">
                <img style="width: 100%; aspect-ratio: 1 / 2.3;" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-Siders.png" alt="">
            </div>
            <div class="col">

                <div class="grid gap-0" style="height: 100%; align-items: center;">

                    <div v-if="GoldApple" class="col-6">
                        <div class="beer-menu-product-card gold-apple">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ GoldApple.price }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="Honey" class="col-6">
                        <div class="beer-menu-product-card honey">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ Honey.price }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="RoseApple" class="col-6">
                        <div class="beer-menu-product-card rose-apple">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ RoseApple.price }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-if="RedBerries" class="col-6">
                        <div class="beer-menu-product-card red-berries">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ RedBerries.price }}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array }
        },
        computed: {
            Honey(){
                return this.products.filter(p => p.name === 'Strongbow Honey')[0]
            },
            RedBerries(){
                return this.products.filter(p => p.name === 'Strongbow Red Berries')[0]
            },
            RoseApple(){
                return this.products.filter(p => p.name === 'Strongbow Rose Apple')[0]
            },
            GoldApple(){
                return this.products.filter(p => p.name === 'Strongbow Gold Apple')[0]
            }
        }
    }
</script>
