<template>
    <div class="mandp-big-beers-section">

        <div class="mandp-beers-section--header big-cbeers"></div>

        <div class="grid">

            <div v-if="Heineken" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Heineken-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Heineken.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="XXLager" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Dos-XX-Lager-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ XXLager.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="XXAmbar" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Dos-XX-Ambar-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ XXAmbar.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="TecateO" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Tecate-Original-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ TecateO.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="TecateLight" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Tecate-Light-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ TecateLight.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="Indio" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Indio-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Indio.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="Sol" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Sol-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Sol.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="CartaBlanca" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Carta-Blanca-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ CartaBlanca.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="Superior" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Superior-cc.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ Superior.price }}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            products: { required: true, type: Array }
        },
        computed: {
            Heineken(){
                return this.products.filter(p => p.name === 'Heineken')[0]
            },
            XXLager(){
                return this.products.filter(p => p.name === 'Dos Equis')[0]
            },
            XXAmbar(){
                return this.products.filter(p => p.name === 'Dos Equis Ambar')[0]
            },
            TecateO(){
                return this.products.filter(p => p.name === 'Tecate')[0]
            },
            TecateLight(){
                return this.products.filter(p => p.name === 'Tecate Light')[0]
            },
            Indio(){
                return this.products.filter(p => p.name === 'Indio')[0]
            },
            Sol(){
                return this.products.filter(p => p.name === 'Sol')[0]
            },
            CartaBlanca(){
                return this.products.filter(p => p.name === 'Carta blanca')[0]
            },
            Superior(){
                return this.products.filter(p => p.name === 'Superior')[0]
            },
        }
    }
</script>
