<template>
    <div class="mandp-big-beers-section">

        <div class="mandp-beers-section--header big-beers"></div>

        <div class="grid">

            <div v-if="TecateOC" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Tecate-Original-c.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ TecateOC.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="TecateLightC" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Tecate-Light-c.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ TecateLightC.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="SolC" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Sol-c.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ SolC.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="CartaBlancaC" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Carta-Blanca-c.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ CartaBlancaC.price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="MillerHighLC" class="col-12 -mb-5">
                <div class="grid gap-0 flex justify-content-center align-items-center">
                    <div class="col-10">
                        <img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Miller-c.png" alt="">
                    </div>
                    <div class="col-2 flex justify-content-center align-items-center" style="border-bottom: 1px solid #f2f2f2;">
                        <p class="mandp-beers-section--price">
                            {{ MillerHighLC.price }}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            cproducts: { required: true, type: Array }
        },
        computed: {
            TecateOC(){
                return this.cproducts.filter(p => p.name === 'Tecate')[0]
            },
            TecateLightC(){
                return this.cproducts.filter(p => p.name === 'Tecate Light')[0]
            },
            SolC(){
                return this.cproducts.filter(p => p.name === 'Sol')[0]
            },
            CartaBlancaC(){
                return this.cproducts.filter(p => p.name === 'Carta blanca')[0]
            },
            MillerHighLC(){
                return this.cproducts.filter(p => p.name === 'Miller High Life')[0]
            }
        }
    }
</script>
