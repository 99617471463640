<template>
    <div class="mandp-heineken-section">

        <div class="grid">
            <div :class="{
                  'col-5': ProductsCount >= 7,
                  'col-4': ProductsCount >= 5 && ProductsCount <= 6,
                  'col-3': ProductsCount <= 4
            }">
                <Carousel :value="carousel_items" :numVisible="1" circular :showNavigators="false" :autoplay-interval="5000">
                    <template #item="slotProps">
                        <img style="width: 100%; height: 100%" :src="slotProps.data" alt="">
                    </template>
                </Carousel>
                <!--<img style="width: 100%" src="https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/hnk-section-banner-01.png" alt="">-->
            </div>
            <div class="col">
                <div class="grid gap-0" style="height: 100%; align-items: center;">
                    <div v-if="Heineken" class="col-6">
                        <div class="beer-menu-product-card hnk">
                            <div class="mandp-heineken-section--price hnk">
                                <p>
                                    {{ Heineken.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="HeinekenSilver" class="col-6">
                        <div class="beer-menu-product-card hnk-silver">
                            <div class="mandp-heineken-section--price hnk">
                                <p>
                                    {{ HeinekenSilver.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="Amstel" class="col-6">
                        <div class="beer-menu-product-card amstel">
                            <div class="mandp-heineken-section--price hnk">
                                <p>
                                    {{ Amstel.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="Miller" class="col-6">
                        <div class="beer-menu-product-card miller-high">
                            <div class="mandp-heineken-section--price hnk">
                                <p>
                                    {{ Miller.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="Pilsner" class="col-6">
                        <div class="beer-menu-product-card bohemia-plisner">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ Pilsner.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="Vienna" class="col-6">
                        <div class="beer-menu-product-card bohemia-vienna">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ Vienna.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="Weizen" class="col-6">
                        <div class="beer-menu-product-card bohemia-weizen">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ Weizen.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="Cristal" class="col-6">
                        <div class="beer-menu-product-card bohemia-cristal">
                            <div class="mandp-heineken-section--price">
                                <p>
                                    {{ Cristal.price }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Carousel from "primevue/carousel"

    export default {
        props: {
            products: { required: true, type: Array, },
            bohemia_products: { required: true, type: Array, },
        },
        data(){
            return {
                carousel_items: [
                    "https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/hnk-section-banner-01.png",
                    "https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-HSilver.png",
                    "https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-AmstelUltra.png",
                    "https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-Miller.png",
                    //"https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-Bohemia.png",
                    //"https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-Bohemia-Vienna.png",
                    //"https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-Bohemia-Weizen.png",
                    //"https://menuandpricing.fra1.cdn.digitaloceanspaces.com/beers-menu/v2-theme/Banner-Bohemia-Cristal.png",
                ]
            }
        },
        computed: {
            ProductsCount(){
                return this.products.length + this.bohemia_products.length
            },
            Heineken(){
                return this.products.filter(p => p.name === 'Heineken')[0]
            },
            HeinekenSilver(){
                return this.products.filter(p => p.name === 'Heineken Silver')[0]
            },
            Amstel(){
                return this.products.filter(p => p.name === 'Amstel Ultra')[0]
            },
            Miller(){
                return this.products.filter(p => p.name === 'Miller High Life')[0]
            },
            Pilsner(){
                return this.bohemia_products.filter(p => p.name === 'Bohemia Plisner Clara')[0]
            },
            Vienna(){
                return this.bohemia_products.filter(p => p.name === 'Bohemia Vienna Oscura')[0]
            },
            Weizen(){
                return this.bohemia_products.filter(p => p.name === 'Bohemia Weizen')[0]
            },
            Cristal(){
                return this.bohemia_products.filter(p => p.name === 'Bohemia Cristal')[0]
            },
        },
        components: {
            Carousel,
        }
    }
</script>
